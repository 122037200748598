dl#ind {
    dd {
        padding: 100px span(0.3);

        h5.title {
            color: #2c2c2c;
            font-size: responsive 28px 48px;
            position: absolute;

        }

        #blockName {
            lost-utility: clearfix;
            margin-bottom: 80px;
        }

        &#about {
            background: #f0f0f0;
            position: relative;

            .left {
                width: span(7 of 14);
                height: 100%;
                position: absolute;
                overflow: hidden;
                left: 0;
                top: 0;
                padding: span(0.5);

                &::before {
                    content: '';
                    left: span(0.5);
                    top: span(0.5);
                    right: span(0.5);
                    bottom: span(0.5);
                    position: absolute;
                    background: url(#{$img-path}/row2Bg.jpg) no-repeat right;
                    background-size: cover;
                }

                img {
                    width: 100%;
                    height: auto;
                }
            }

            .right {
                width: span(7 of 14);
                margin-left: span(7.5 of 14);
                padding: 150px 0 20px 0;

                h2 {
                    b {
                        margin-left: 10px;
                        display: inline-block;
                        vertical-align: middle;
                    }
                }
            }

            p {
                line-height: 30px;
                font-size: 15px;
                color: #12061d;
                margin-top: 40px;
            }
        }

        &#class {
            background: url(#{$img-path}/classBg.jpg) repeat;
            color: #12061d;

            .mTable {
                display: none;
            }

            .react-tabs__tab-panel {
                lost-utility: clearfix;
            }
            .left {
                float: left;
                width: span(2.5 of 14);
                background: #ffffff;
                border-radius: 4px;
                box-shadow: 0 0 35px rgba(0, 0, 0, .1);
                padding: span(0.5) span(0.3);

                h2 {
                    font-size: 20px;

                    b {
                        color: #c1ab55;
                        margin-right: 10px;
                    }
                }

                .heart {
                    position: absolute;
                    left: 0;
                }

                ul {
                    font-size: 13px;
                    margin-top: 25px;

                    li {
                        position: relative;
                        line-height: 18px;
                        color: #12061d;
                        margin-bottom: 8px;
                        padding-left: 65px;
                        &.noStopClass{
                            padding-left: 0;
                        }
                    }

                    b {
                        position: absolute;
                        left: 0;
                        top: 0;
                        color: #1fa0d0;
                        margin-bottom: 5px;
                    }

                    i {
                        color: #c1ab55;
                        font-weight: bold;
                        margin-left: 3px;
                    }
                }
            }

            .right {
                float: right;
                margin-left: span(0.5);
                width: span(11 of 14);
                position: relative;

                ul.level {
                    font-size: 15px;
                    position: absolute;
                    top: -40px;
                    width: 100%;

                    .adv {
                        color: #c76917;
                    }

                    .started {
                        color: #4D9BB3;
                    }

                    .normal {
                        color: #8C9F09;
                    }

                    li {
                        display: inline-block;
                        margin-right: span(0.2);

                        &:last-child {
                            margin-right: 0;
                        }
                    }

                    &.three {
                        .adv {
                            color: #c76917;
                        }

                        .started {
                            color: #8C9F09;
                        }

                        .normal {
                            color: #4D9BB3;
                        }
                    }
                }
            }
        }

        &#price {
            background: url(#{$img-path}/priceBg.jpg) repeat;

            #blockName {
                margin-bottom: 120px;
            }

            ul.price {
                padding: 0 span(0.5);
                lost-utility: clearfix;

                >li {
                    background: #ffffff;
                    padding: span(0.4) span(0.4) 30px span(0.4);
                    box-shadow: 0 0 35px rgba(0, 0, 0, .1);
                    border-radius: 10px;
                    lost-column: 1/4 4 40px;
                    position: relative;
                    margin-bottom: 35px;
                }

                h3 {
                    font-size: 30px;
                    color: #4fd1d9;
                    padding-top: 100px;
                    margin-bottom: 30px;
                }

                ol {
                    li {
                        margin-bottom: 4px;
                        background: url(#{$img-path}/ao.png) no-repeat left 8px;
                        padding-left: 12px;
                        font-size: 14px;
                        line-height: 20px;
                    }

                    b {
                        color: #e86800;
                    }
                }

                .class {
                    background: #4fd1d9;
                    font-weight: bolder;
                    font-size: 68px;
                    color: #fff;
                    border-radius: 100%;
                    width: 110px;
                    height: 110px;
                    line-height: 110px;
                    text-align: center;
                    position: absolute;
                    right: 30px;
                    top: 30px;
                }
            }
        }

        &#location {
            padding: 100px 0 0 0;

            #blockName {
                margin-bottom: 40px;
            }

            h2 {
                margin-left: span(2.8 of 14);
                margin-bottom: 40px;
                line-height: 25px;
            }

            .map {
                height: 600px;
            }

            .react-tabs {
                [name="one"] {
                    .map {
                        background: url(#{$img-path}/one.jpg) no-repeat center;
                    }
                }

                [name="two"] {
                    .map {
                        background: url(#{$img-path}/twoMap.jpg) no-repeat center;
                    }
                }

                [name="four"] {
                    .map {
                        background: url(#{$img-path}/miniMap.jpg) no-repeat center;
                    }
                }
            }

        }
    }
}