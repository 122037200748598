@media screen and (max-width: 1250px) {
    dl#ind{
        dd{
            &#price{
                ul.price{
                    padding: 0;
                    >li{
                        lost-column: 1/3 3 30px;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: $pad-1150) {
    dl#ind{
        dd{
            &#about{
                .right{
                    padding-top: 50px;
                }
            }
            &#class{
                #blockName{
                    margin-bottom: 30px;
                }
                .left{
                    float: none;
                    width: span(14 of 14);
                    padding: span(0.3);
                    
                    ul{
                        > li{
                            float: none;
                        }
                    }
                }
                .right{
                    float: left;
                    margin-left: 0;
                    width: 100%;
                    margin-top: 80px;
                }
            }
        }
    }
}
@media screen and (max-width: $pad-1000) {
    dl#ind{
        dd{
            &#price{
                #blockName{
                    margin-bottom: 80px;
                }
            }
        }
    }
}
@media screen and (max-width: $pad-900) {
    dl#ind{
        dd{
            &#price{
                ul.price{
                    h3{
                        padding-top: 20px;
                        text-align: center;
                    }
                    .class{
                        position: static;
                        margin: 0 auto;
                    }
                }
            }
            &#location{
                h2{
                    margin-left: span(3.5);
                }
            }
        }
    }
}
@media screen and (max-width: 860px) {
    dl#ind{
        dd{
            &#class{
                .classTable{
                    display: none;
                }
                .mTable{
                    display: table;
                }
                .cTitle{
                    display: none;
                }
                .mcTitle{
                    display: block;
                }
            }
        }
    }
}
@media screen and (max-width: 800px) {
    dl#ind{
        dd{
            &#about{
                padding: span(0.5);
                p{
                    margin-top: 20px;
                    line-height: 25px;
                    font-size: 14px;
                }
                .left{
                    width: 100%;
                    height: 150px;
                    position: relative;
                    overflow: hidden;
                    &::before{
                        left: span(0);
                        top: span(0);
                        right: span(0);
                        bottom: span(0);
                    }
                }
                .right{
                    width: 100%;
                    margin-left: 0;
                    padding-top: 30px;
                }
            }
            &#price{
                ul.price{
                    >li{
                        padding: span(0.5) span(1);
                        lost-column: 1/2 2 20px;
                    }
                    .class{
                        width: 120px;
                        height: 120px;
                        line-height: 120px;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 700px) {
    dl#ind{
        dd{
            h5.title{
                position: static!important;
                margin-bottom: 30px;
                text-align: center;
            }
            &#class{
                .left{
                    ul{
                        margin-top: 10px;
                    }
                    h2{
                        display: none;
                    }
                }
            }
            &#price{
                #blockName{
                    margin-bottom: 20px;
                }
            }
            &#location{
                h2{
                    margin-left: 0;
                    text-align: center;
                }
                .map{
                    height: 400px;
                }
            }
        }
    }
}

