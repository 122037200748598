@media screen and (max-width: 800px) {
    header {
        ul.menu {
            display: none;
        }
    }

    #mobile-mu {
        display: block;
        position: fixed;
        right: 20px;
        top: 15px;
        width: 28px;
        z-index: 10000000;
        overflow: hidden;
        height: 28px;

        &.toggled {
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
        }

        &.active {
            ul.buns {
                li {
                    background: #fff;
                }
            }
        }
    }

    a.target-burger {
        position: absolute;
        display: block;
        transition: all 0.5s ease;
        z-index: 11;
        width: 28px;
        height: 28px;

        &:hover {
            cursor: pointer;
        }

        &.toggled {
            right: 15px;
            top: 15px;

            ul.buns {
                li.bun {
                    transform: rotate(45deg) translateZ(0);
                    background: #fff;

                    &:last-child {
                        transform: rotate(-45deg) translateZ(0);
                    }

                    &:nth-child(2n) {
                        opacity: 0;
                    }

                }
            }
        }

        ul.buns {
            width: 28px;
            height: 1.625em;
            padding: 0;
            position: absolute;
            transition: transform 1s cubic-bezier(0.23, 1, 0.32, 1), color 1s cubic-bezier(0.23, 1, 0.32, 1);
            transform: translateZ(0);
            color: #fff;

            li.bun {
                width: 100%;
                height: 3px;
                background-color: #000;
                position: absolute;
                top: 50%;
                margin-top: -0.75px;
                transform: translateY(-5px) translateZ(0);
                transition: transform 1s cubic-bezier(0.23, 1, 0.32, 1), background-color 1s cubic-bezier(0.23, 1, 0.32, 1);

                &:last-child {
                    transform: translateY(9px) translateZ(0);
                }

                &:nth-child(2n) {
                    transform: translateY(2px) translateZ(0);
                    opacity: 1;
                }
            }
        }
    }

    nav.main-nav {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
        transform: scale(0.85);
        transition: all 0.5s ease;
        font-family: $font-yahei;

        &.toggled {
            position: fixed;
            width: 100%;
            height: 100%;
            background: #000;
            z-index: 10;
            transform: scale(1);
            opacity: 0.6;
            transition: all 0.5s ease;
            filter: none;
        }

        ul {
            padding: 60px 0 0 0;

            li {
                border-bottom: 1px solid rgba(255, 255, 255, 0.15);
                padding-left: span(0.5 of 15);

                a {
                    font-size: 1.250em;
                    color: #fff;
                    padding: 1em 0;
                    font-weight: 300;
                    display: block;
                    transition: all 0.5s ease;

                    &:last-child {
                        border: none;
                    }

                    &:hover {
                        opacity: 0.45;
                        transition: all 0.5s ease;
                        padding: 1em;
                    }
                }
            }
        }
    }

    #outer-wp {
        position: relative;
        transition: all 0.5s ease;

        &.toggled {
            filter: blur(8px);
            transition: all 0.5s ease;
        }
    }
}