@media screen and (max-width: $mobile-640) {
    #outer-wp{
        .inner{
            padding: 0;
        }
    }
    header{
        padding: 0;
        &.fix-nav {
            &.fix-nav-shrink{
                height: 60px;
                ul.menu{
                    top: -5px;
                }
            }
        }
        a.logo{
            margin-top: 15px;
            max-width: span(5 of 14);
        }
        ul.menu{
            position: relative;
            width: 100%;
            right: 0;
            margin-top: 10px;
            li{
                lost-column: 1/3 3 0;
            }
            a{
                height: 40px;
                line-height: 40px;
                &:focus{
                    background: #000;
                }
                &:after{
                    content: none;
                }
            }
        }
    }
}
@media screen and (max-width: $mobile-468) {
    header{
        a.logo{
            max-width: span(6 of 14);
        }
    }
}
@media screen and (max-width: 350px) {
    .mTable{
        tbody{
            th{
                padding: 5px 3px;
            }
        }
    }
}
