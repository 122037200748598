@media screen and (max-width: $pad-900) {
    ul.react-tabs__tab-list {
        margin-left: span(3.5);
    }

    dl#ind {
        dd {
            &#location {
                ul.react-tabs__tab-list {
                    width: span(10 of 14);

                    li {
                        lost-column: 1/4 4 8px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 700px) {
    ul.react-tabs__tab-list {
        width: 100%;
        margin-left: 0;

        li {
            lost-column: 1/2 2 5PX;
        }
    }

    dl#ind {
        dd {
            &#location {
                ul.react-tabs__tab-list {
                    width: 100%;

                    li {
                        lost-column: 1/3 3 8px;
                    }
                }
            }
        }
    }
}