@import "grids";
@import "default";
@import "slider";

@import "global";
@import "pad/global";
@import "mobile/global";

@import "pad/mobile-mu";

* {
	box-sizing: border-box;
	touch-action: pan-y;
}

a {
	text-decoration: none;
}

*:focus {
	outline: 0;
}

input[type="submit"],
button,
input[type="button"] {
	-webkit-apperance: none;
	cursor: pointer;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

html,
body {
	font-family: $font-yahei;
	height: 100%;
}

body {
	overflow-x: hidden;
}

.inner {
	width: span(14, $susy);
	max-width: 1580px;
	margin: 0 auto;
	position: relative;
	lost-utility: clearfix;
	padding: 0 span(0.3);
	// background: susy-svg-grid() no-repeat scroll;
}

#outer-wp {
	height: 100%;



	footer {
		height: 80px;
		background: #000000;
		color: #fff;

		h2 {
			font-size: 12px;
			font-family: $font-ming;
			left: 0px;
			padding-top: 25px;
		}

		.copyright {
			font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
			font-size: 10px;
			margin-top: 5px;
		}
	}
}

@import "index";
@import "pad/index";
@import "mobile/index";